export const microBlog = (options) => {
  return `https://service.weibo.com/share/share.php
      ?title= %2523%E5%8D%83%E5%9F%8E%E4%B8%87%E8%B1%A1%2523 我在%23%E6%98%8E%E5%9F%BA%E8%AE%BE%E8%AE%A1%E5%A4%A7%E8%B5%9B%23看到 ${options.author}  的作品  《${options.worksname}》快来为作品助力！
      &url=${options.url}
      &pic=${options.pic}?x-oss-process=image/resize,p_10
      `.replace(/\s/g, "");
};

export const qqSpace = (options) => {
  return `http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${options.url}&title=我在为${options.author}的作品《 ${options.worksname}》助力&desc=我在明基设计大赛为${options.author}的作品《 ${options.worksname}》助力，快来为作品助力，选择你的精彩！&summary=和我一起为他助力&site={{100*100}}&pics=${options.pic}`
}


export const QQ = (options) => {
  return `http://connect.qq.com/widget/shareqq/index.html?url=${options.url}&sharesource=qzone&title=我在为${options.author}的作品《 ${options.worksname}》助力&pics=${options.pic}&summary=和我一起为他助力&desc=我在明基设计大赛为${options.author}的作品《 ${options.worksname}》助力，快来为作品助力，选择你的精彩！`
}
